<template>
  <v-app>
    <v-container>
      <v-row no-gutters>
        <v-col class="ml-2" lg="5" md="5" sm="5" xs="10">
          <v-sparkline
            :value="value"
            :gradient="gradient"
            :smooth="radius || false"
            :padding="padding"
            :height="100"
            :line-width="width"
            :stroke-linecap="lineCap"
            :gradient-direction="gradientDirection"
            :fill="fill"
            :type="type"
            :auto-line-width="autoLineWidth"
            auto-draw
          ></v-sparkline>
        </v-col>
        <v-col align-self="end">
          <div id="fadeIn">
            <p class="text-left mb-0">
              <b>Stress</b>
            </p>
          </div>
          <div id="fadeIn2">
            <p class="text-left pl-2 mb-0">
              <b>Database</b>
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-12">
        <v-col>
          <h3 class="text-left">Project</h3>
          <p class="text-left text-justify">
            The human response to acute stress can be studied in detail in
            controlled settings, usually in a laboratory environment. To this
            end, many studies employ acute stress paradigms to probe
            stress-related outcomes in healthy and patient populations. The
            STRESS-NL database is a collaborative interdisciplinary initiative
            which combines (neuro)biological, physiological, and behavioral data
            across many acute stress studies and accelerate our understanding of
            the human acute stress response in health and disease. Currently,
            researchers from 12 Dutch research groups and 6 different Dutch
            universities in the stress field collaborated to achieve an accurate
            inventory of (neuro)biological, physiological, and behavioral data
            from laboratory-based human studies that used acute stress tests.
            This database creates a unique framework that enables human stress
            research to take new avenues in explorative and hypothesis-driven
            data analyses with high statistical power.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-left">How to access data</h3>
          <p class="text-left text-justify">
            The STRESS-NL database is governed by a consortium agreement,
            allowing data to be accessed by consortium members
            <router-link to="/upload">(‘how to contribute data’)</router-link>
            and external parties (non-consortium members). Consortium members
            and external parties can gain access to anonymized individual
            participant data via an analysis plan submitted to the STRESS-NL
            Steering Committee. For non-consortium members, there will be a
            limited fee of 500€ for approved analysis plans to finance database
            updates and maintenance. The procedure can be found
            <router-link to="./request_data">here</router-link>.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-left">Contribute data</h3>
          <p class="text-left text-justify">
            Interested PIs can contact us for an informal discussion about the
            eligibility of their data. Inclusion criteria and procedure for
            uploading data can be found
            <router-link to="/upload">here</router-link>.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="text-left">Contact</h3>
          <p class="text-left text-justify">
            We are happy to discuss informal inquiries or help you out with any
            additional questions. You can contact us via the link below.
          </p>
          <v-btn
            class="mb-10 mt-5"
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="mailContact"
          >
            Contact us
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

export default {
  data: () => ({
    width: 2,
    radius: 10,
    padding: 1,
    lineCap: "round",
    gradient: gradients[5],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    gradientDirection: "top",
    gradients,
    fill: false,
    type: "trend",
    autoLineWidth: false,
  }),
  mounted: function() {
    this.$nextTick(() => {
      this.onLoaded();
    });
  },
  methods: {
    mailContact() {
      window.location.href = "mailto:stresseu@amsterdamumc.nl";
    },
    onLoaded() {
      document.getElementById("fadeIn").children[0].className += " load";
      setTimeout(() => {
        document.getElementById("fadeIn2").children[0].className += " load";
      }, 1900);
    },
  },
};
</script>

<style scoped>
#fadeIn p {
  color: #ffd200;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in;
  -moz-transition: opacity 1s ease-in;
  -ms-transition: opacity 1s ease-in;
  -o-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
}

#fadeIn p.load {
  opacity: 1;
}

#fadeIn2 p {
  color: black;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -ms-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}

#fadeIn2 p.load {
  opacity: 1;
}
</style>
